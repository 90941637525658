<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Admin" />
		<section>
			<div class="form-title">Time Interval Revenue Values</div>
			<b-table :data="listArr" :columns="columns"></b-table>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-button class="is-primary margin-top-20" type="submit" @click="getData"
				>Get Results</b-button
			>
			<b-button
				class="is-info form-btn margin-top-20"
				type="submit"
				@click="getToday"
				>Get Today's Results</b-button
			>
		</section>
		<div class="hero is-warning margin-top-20">
			<div class="hero-body">
				<div class="container">
					<div v-if="loading">
						<div class="loading-text">Loading Grand Totals .......</div>
					</div>
					<div v-if="!loading">
						<div style="font-size: larger">
							<div>
								<span class="blue"><b>Invoice total:&nbsp;</b></span
								><b>{{ invoiceTotals }}</b>
							</div>
							<div>
								<span class="green"><b>Payment:&nbsp;</b></span
								><b>{{ paymentTotals }}</b>
							</div>
							<div>
								<span class="alarm"><b>Balance:&nbsp;</b></span
								><b>{{ balanceTotals }}</b>
							</div>
						</div>
						<b-button class="is-danger margin-top-20" @click="getGrandTotals"
							>Refresh Grand Totals</b-button
						>
					</div>
				</div>
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import Store from '@/store'
import { useQuery, useResult } from '@vue/apollo-composable'
import GetOrdersByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getOrdersByTimeInterval.query.gql'
import GetDispatchesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getDispatchesByTimeInterval.query.gql'
import GetDispatchesByDeliveryTimeInterval from '@/_srcv2/graphql/admin-reports/queries/GetDispatchesByDeliveryTimeInterval.graphql'
import GetInvoicesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getInvoicesByTimeInterval.query.gql'
import GetUninvoicedDispatchesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getUninvoicedDispatchesByTimeInterval.query.gql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import SearchInvoicesForPayment from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/grandTotals.graphql'

export default {
	name: 'TimeIntervalRevenue',
	components: {
		SubNavbar,
	},
	setup() {
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		const today = ref(new Date())
		const fetchDates = () => {
			return new Promise((resolve, reject) => {
				if (today.value !== null) {
					selectedBeginningDate.value = today.value
					selectedEndingDate.value = today.value
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const getToday = () => {
			fetchDates().then(() =>
				setTimeout(() => {
					getData()
				}, 200),
			)
		}
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		// Time Interval Orders
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result: resultOrder, refetch: refetchOrder } = useQuery(
			GetOrdersByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedOrders = useResult(
			resultOrder,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		console.log('searchedOrders', searchedOrders)
		// Time Interval Dispatches
		const { result: resultDispatch, refetch: refetchDispatch } = useQuery(
			GetDispatchesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedDispatches = useResult(
			resultDispatch,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		console.log('searchedDispatches', searchedDispatches)
		// todo Delivery Time Interval Dispatches
		const { result: resultDeliveryDispatch, refetch: refetchDeliveryDispatch } =
			useQuery(
				GetDispatchesByDeliveryTimeInterval,
				() => ({
					company_id: Store.getters.getUserCurrentCompany,
					bd: parameters.beginningDate,
					ed: parameters.endingDate,
				}),
				options,
			)
		const searchedDeliveryDispatches = useResult(
			resultDeliveryDispatch,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		console.log('searchedDeliveryDispatches', searchedDeliveryDispatches)
		// todo Time Interval Invoices
		const { result: resultInvoice, refetch: refetchInvoice } = useQuery(
			GetInvoicesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedInvoices = useResult(
			resultInvoice,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		console.log('searchedInvoices', searchedInvoices)
		// Time Interval Uninvoiced Dispatches
		const {
			result: resultUninvoicedDispatches,
			refetch: refetchUninvoicedDispatches,
		} = useQuery(
			GetUninvoicedDispatchesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedUninvoicedDispatches = useResult(
			resultUninvoicedDispatches,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		console.log('searchedUninvoicedDispatches', searchedUninvoicedDispatches)
		let tableValues = ref([])
		const getTableValues = () => {
			tableValues.value = []
			tableValues.value.push({
				type: 'Delivered Dispatch',
				revenue: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.vat_credit,
				),
				total: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.line_price_total_credit +
						searchedDeliveryDispatches.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Order',
				revenue: floatedCurrencyAddedNumber(
					searchedOrders.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedOrders.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedOrders.value.line_price_total_credit +
						searchedOrders.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Dispatch',
				revenue: floatedCurrencyAddedNumber(
					searchedDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedDispatches.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedDispatches.value.line_price_total_credit +
						searchedDispatches.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Invoice',
				revenue: floatedCurrencyAddedNumber(
					searchedInvoices.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedInvoices.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedInvoices.value.line_price_total_credit +
						searchedInvoices.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Uninvoiced Dispatches',
				revenue: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.vat_credit,
				),
				total: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.line_price_total_credit +
						searchedUninvoicedDispatches.value.vat_credit,
				),
			})
		}
		const getData = () => {
			console.log('getData is fired')
			refetchOrder()
				.then(() => refetchDispatch())
				.then(() => refetchDeliveryDispatch())
				.then(() => refetchInvoice())
				.then(() => refetchUninvoicedDispatches())
				.then(() => {
					getTableValues()
				})
		}
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'type',
				label: 'Document Type',
				width: '170',
			},
			{
				field: 'revenue',
				label: 'Revenue',
				width: '150',
			},
			{
				field: 'vat',
				label: 'VAT',
				width: '150',
			},
			{
				field: 'total',
				label: 'Total',
				width: '150',
			},
		]
		const { floatedCurrencyAddedNumber } = useReportNumber()
		onMounted(() => {
			setTimeout(() => {
				getData()
			}, 100)
		})
		// **********************************************************************************
		const { result, refetch, loading } = useQuery(
			SearchInvoicesForPayment,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const searchQueryResults = useResult(
			result,
			null,
			(data) => data.document_transactions,
		)
		const invoiceTotals = ref(0)
		const rawITV = ref(0)
		const paymentTotals = ref(0)
		const rawPTV = ref(0)
		// const balanceTotals = ref(0)
		const rawBalance = ref(0)

		// -----------------------------------------------------------------------------------
		// const grandBalance = ref(0)

		const invoiceBalance = (
			transactionType,
			invoiceDebit,
			invoiceCredit,
			vatDebit,
			vatCredit,
		) => {
			let correctedBalance
			if (transactionType === 'sales invoice ') {
				correctedBalance = Math.round((invoiceCredit + vatCredit) / 100)
			} else {
				correctedBalance = Math.round((invoiceDebit + vatDebit) / 100)
			}
			return correctedBalance
		}
		//  ----------------------------------------------------------------
		const invoicePayment = (transactionType, paymentDebit, paymentCredit) => {
			let correctedPayment
			if (transactionType === 'sales invoice ') {
				correctedPayment = Math.round(paymentCredit / 100) || 0
			} else {
				correctedPayment = Math.round(paymentDebit / 100) || 0
			}
			return correctedPayment
		}
		const formatCurrency = (amount) => {
			const options = { style: 'currency', currency: 'SEK' }
			const numberFormat = new Intl.NumberFormat('sv-SE', options)

			console.log(numberFormat.format(amount))
			return numberFormat.format(amount)
		}
		const getGrandTotals = () => {
			refetch()
				.then((result) => {
					console.log(
						'result.data.document_transactions',
						result.data.document_transactions,
					)
					/* this is our initial value i.e. the starting point*/
					const initialValue = 0

					/* reducer method that takes in the accumulator and next item */
					// value[0].invoice_totals_aggregate.aggregate.sum.line_price_total_credit
					const reducer = (accumulator, item) => {
						return accumulator + item
					}

					/* we give the reduce method our reducer function
	       and our initial value */
					const invoiceTotalsArr = searchQueryResults.value.map((item) =>
						invoiceBalance(
							item.transaction_type,
							item.invoice_totals_aggregate.aggregate.sum
								.line_price_total_debit,
							item.invoice_totals_aggregate.aggregate.sum
								.line_price_total_credit,
							item.invoice_totals_aggregate.aggregate.sum.vat_debit,
							item.invoice_totals_aggregate.aggregate.sum.vat_credit,
						),
					)
					const invoicePaymentsArr = searchQueryResults.value.map((item) =>
						invoicePayment(
							item.transaction_type,
							item.invoice_payments_aggregate.aggregate.sum.payment_debit,
							item.invoice_payments_aggregate.aggregate.sum.payment_credit,
						),
					)
					rawITV.value = invoiceTotalsArr.reduce(reducer, initialValue)
					rawPTV.value = invoicePaymentsArr.reduce(reducer, initialValue)
					console.log('invoiceTotalsArr', invoiceTotalsArr)
					console.log('invoiceTotals', invoiceTotals.value)
				})
				.then(() => {
					console.log('rawITV.value', rawITV.value)
					console.log('rawPTV.value', rawPTV.value)
					console.log('typeof rawITV.value', typeof rawITV.value)
					console.log('typeof rawPTV.value', typeof rawPTV.value)
					invoiceTotals.value = formatCurrency(rawITV.value)
					paymentTotals.value = formatCurrency(rawPTV.value)
					console.log('invoiceTotals.value', invoiceTotals.value)
					console.log('paymentTotals.value', paymentTotals.value)
					rawBalance.value = rawITV.value - rawPTV.value
					console.log('rawBalance.value', rawBalance.value)
					console.log('typeof rawBalance.value', typeof rawBalance.value)
				})
		}
		const balanceTotals = computed(() => {
			return formatCurrency(rawBalance.value)
		})
		console.log('balanceTotals.value', balanceTotals.value)
		return {
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			getData,
			listArr,
			columns,
			getToday,
			getGrandTotals,
			invoiceTotals,
			paymentTotals,
			balanceTotals,
			loading,
			refetch,
		}
	},
}
</script>

<style scoped>
.loading-text {
	margin-top: 30px;
	color: red;
	font-size: xx-large;
	font-weight: bolder;
}
.form-title {
	font-weight: bolder;
	font-size: 1.5em;
	color: #9b1c1c;
	margin-left: 5px;
	margin-bottom: 20px;
}
.form-btn {
	margin-left: 20px;
}
.margin-top-20 {
	margin-top: 20px;
}
.alarm {
	color: red;
}
.green {
	color: green;
}
.blue {
	color: blue;
}
</style>
